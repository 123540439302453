import { find, get } from 'lodash'
import Container from '@theme/components/utils/Container'

export default {
  components: {
    Container,
    LanguageSwitcher: () => import('@theme/components/shop/LanguageSwitcher'),
  },
  computed: {
    browserLanguage() {
      return process.client
        ? get(window, 'navigator.language', false) || get(window, 'navigator.userLanguage', false)
        : false
    },
    browserLanguageCode() {
      return this.browserLanguage ? (this.browserLanguage.slice(0, 2) || '').toLowerCase() : false
    },
    contentText() {
      return this.browserLanguageCode
        ? get(this.$t('components.LanguageBanner.content'), this.browserLanguageCode, false)
        : false
    },
    recommendedLang() {
      return find(get(this.$store, 'state.globals.languages', []), { code: this.browserLanguageCode })
    },
    recommendedLangAlternative() {
      return find(get(this.$store, 'state.locale.alternativeUrls', []), { language: this.browserLanguageCode })
    },
    recommendedLangTarget() {
      const sameDomain = this.$i18n.locales.find(l => l.code === this.browserLanguageCode) || false
      return !!sameDomain
    },
    showBanner() {
      return (
        this.browserLanguage &&
        !this.browserLanguage.startsWith(this.$i18n.locale) &&
        !!this.contentText &&
        this.$store.state.globals.languageBanner
      )
    },
  },
  methods: {
    close() {
      this.$store.commit('globals/CLOSE_LANGUAGE_BANNER')
      this.hide()
    },
    hide() {
      const root = document.documentElement
      root.style.setProperty('--language-banner-height', '0px')
    },
  },
}
